define('ember-table/mixins/scroll-handler', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Mixin.create({
    onScroll: Ember['default'].K,
    scrollElementSelector: '',

    didRender: function didRender() {
      var _this = this;
      this._super();
      this.$(this.get('scrollElementSelector')).bind('scroll', function (event) {
        Ember['default'].run(_this, _this.onScroll, event);
      });
    },

    willDestroyElement: function willDestroyElement() {
      var $scrollElementSelector = this.$(this.get('scrollElementSelector'));
      if ($scrollElementSelector) {
        $scrollElementSelector.unbind('scroll');
      }
      this._super();
    }
  });

});