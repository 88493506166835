define('ember-table/components/header-row', ['exports', 'ember', 'ember-table/mixins/style-bindings'], function (exports, Ember, StyleBindingsMixin) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(StyleBindingsMixin['default'], {

    classNames: ['ember-table-table-row', 'ember-table-header-row'],
    styleBindings: ['width'],
    columns: Ember['default'].computed.alias('content'),
    width: Ember['default'].computed.alias('rowWidth'),

    rowWidth: null,
    enableColumnReorder: null,
    isShowingSortableIndicator: null,
    sortableIndicatorLeft: null,

    rowWidthSafeString: Ember['default'].computed('rowWidth', function () {
      return new Ember['default'].Handlebars.SafeString('width:' + this.get('rowWidth') + 'px;');
    }),

    // Options for jQuery UI sortable
    sortableOption: Ember['default'].computed(function () {
      return {
        axis: 'x',
        containment: 'parent',
        cursor: 'move',
        helper: 'clone',
        items: ".ember-table-header-cell.sortable",
        opacity: 0.9,
        placeholder: 'ui-state-highlight',
        scroll: true,
        tolerance: 'pointer',
        start: Ember['default'].$.proxy(this.onColumnSortStart, this),
        update: Ember['default'].$.proxy(this.onColumnSortDone, this),
        stop: Ember['default'].$.proxy(this.onColumnSortStop, this),
        sort: Ember['default'].$.proxy(this.onColumnSortChange, this)
      };
    }),

    didRender: function didRender() {
      this._super();
      if (this.get('enableColumnReorder')) {
        this.$('> div').sortable(this.get('sortableOption'));
      }
    },

    willDestroyElement: function willDestroyElement() {
      if (this.get('enableColumnReorder')) {
        // TODO(azirbel): Get rid of this check, as in onColumnSortDone?
        var $divs = this.$('> div');
        if ($divs) {
          $divs.sortable('destroy');
        }
      }
      this._super();
    },

    //saving start position in ui.item object
    onColumnSortStart: function onColumnSortStart(event, ui) {
      ui.item.startPosition = ui.item.index();
    },

    onColumnSortStop: function onColumnSortStop() {
      this.set('isShowingSortableIndicator', false);
    },

    onColumnSortChange: function onColumnSortChange() {
      var left = this.$('.ui-state-highlight').offset().left - this.$().closest('.ember-table-tables-container').offset().left;
      this.set('isShowingSortableIndicator', true);
      this.set('sortableIndicatorLeft', left);
    },

    onColumnSortDone: function onColumnSortDone(event, ui) {
      var toIndex = ui.item.index();
      var fromIndex = ui.item.startPosition;
      this.$('> div').sortable('cancel');
      this.sendAction('columnDidSort', fromIndex, toIndex);
    },

    actions: {
      toggleTableCollapse: function toggleTableCollapse() {
        this.sendAction('toggleTableCollapse');
      }
    }
  });

});