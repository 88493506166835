define('ember-table/mixins/table-block', ['exports', 'ember', 'ember-table/mixins/style-bindings'], function (exports, Ember, StyleBindingsMixin) {

  'use strict';

  exports['default'] = Ember['default'].Mixin.create(StyleBindingsMixin['default'], {
    classNames: ['ember-table-table-block'],
    styleBindings: ['width', 'height'],
    itemViewClass: null,
    columns: null,
    content: null,
    scrollLeft: null,
    rowWidth: null,
    rowHeight: null,

    width: Ember['default'].computed.alias('blockWidth'),
    height: Ember['default'].computed.alias('rowHeight'),

    onScrollLeftDidChange: Ember['default'].observer('scrollLeft', function () {
      this.$().scrollLeft(this.get('scrollLeft'));
    })

  });

});