define('ember-table/models/row', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].ObjectProxy.extend({
    content: null,
    tableComponent: null,

    isShowing: true,
    isHovered: false,

    isSelected: Ember['default'].computed('tableComponent.selection.[]', {
      set: function set(key, val) {
        this.get('tableComponent').setSelected(this, val);
        return this.get('tableComponent').isSelected(this);
      },
      get: function get() {
        return this.get('tableComponent').isSelected(this);
      }
    })
  });

});