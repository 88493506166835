define('ember-table/components/table-cell', ['exports', 'ember', 'ember-table/mixins/style-bindings'], function (exports, Ember, StyleBindingsMixin) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(StyleBindingsMixin['default'], {
    // ---------------------------------------------------------------------------
    // API - Inputs
    // ---------------------------------------------------------------------------

    // TODO: Doc

    classNames: ['ember-table-cell'],
    classNameBindings: 'column.textAlign',
    styleBindings: 'width',

    // ---------------------------------------------------------------------------
    // Internal properties
    // ---------------------------------------------------------------------------

    init: function init() {
      this._super();
      this.contentPathDidChange();
      this.contentDidChange();
    },

    row: null,
    column: Ember['default'].computed.alias('content'),
    width: Ember['default'].computed.alias('column.width'),

    contentDidChange: function contentDidChange() {
      this.notifyPropertyChange('cellContent');
    },

    contentPathDidChange: Ember['default'].observer('column.contentPath', function () {
      var newContentPath = this.get('column.contentPath');
      var oldContentPath = this._oldContentPath;

      if (newContentPath !== oldContentPath) {
        if (oldContentPath) {
          this.removeObserver("row." + oldContentPath, this, this.contentDidChange);
        }
        this._oldContentPath = newContentPath;
        if (newContentPath) {
          this.addObserver("row." + newContentPath, this, this.contentDidChange);
        }
      }
    }),

    cellContent: Ember['default'].computed('row.isLoaded', 'column', {
      set: function set(key, value) {
        var row = this.get('row');
        var column = this.get('column');
        if (!row || !column) {
          return;
        }
        column.setCellContent(row, value);
        return value;
      },
      get: function get() {
        var row = this.get('row');
        var column = this.get('column');
        if (!row || !column) {
          return;
        }
        var value = column.getCellContent(row);
        return value;
      }
    }),

    actions: {
      toggleRowCollapse: function toggleRowCollapse(row) {
        this.sendAction('toggleRowCollapse', row);
      }
    }
  });

});