define('ember-table/components/header-table-container', ['exports', 'ember', 'ember-table/mixins/table-container', 'ember-table/mixins/show-horizontal-scroll'], function (exports, Ember, TableContainer, ShowHorizontalScrollMixin) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(TableContainer['default'], ShowHorizontalScrollMixin['default'], {

    classNames: ['ember-table-table-container', 'ember-table-fixed-table-container', 'ember-table-header-container'],
    height: Ember['default'].computed.alias('headerHeight'),
    width: Ember['default'].computed.alias('tableContainerWidth'),

    numFixedColumns: null,
    fixedColumns: Ember['default'].A,
    tableColumns: Ember['default'].A,
    fixedBlockWidth: null,
    tableBlockWidth: null,
    headerHeight: null,
    tableContainerWidth: null,
    scrollLeft: null,

    actions: {
      toggleTableCollapse: function toggleTableCollapse() {
        this.sendAction('toggleTableCollapse');
      },

      columnDidSort: function columnDidSort(fromIndex, toIndex) {
        this.sendAction('columnDidSort', fromIndex, toIndex);
      }
    }
  });

});