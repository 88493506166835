define('ember-table/components/table-row', ['exports', 'ember', 'ember-table/mixins/style-bindings', 'ember-table/mixins/register-table-component'], function (exports, Ember, StyleBindingsMixin, RegisterTableComponentMixin) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(RegisterTableComponentMixin['default'], StyleBindingsMixin['default'], {

    classNames: 'ember-table-table-row',
    classNameBindings: ['row.isHovered:ember-table-hover', 'row.isSelected:ember-table-selected', 'row.rowStyle', 'isLastRow:ember-table-last-row'],
    styleBindings: ['width', 'height', 'top', 'display'],
    row: Ember['default'].computed.alias('content'),
    columns: Ember['default'].A,
    width: Ember['default'].computed.alias('rowWidth'),
    height: Ember['default'].computed.alias('rowHeight'),

    rowWidthSafeString: Ember['default'].computed('rowWidth', function () {
      return new Ember['default'].Handlebars.SafeString('width:' + this.get('rowWidth') + 'px;');
    }),

    prepareContent: Ember['default'].K,

    top: Ember['default'].computed(function () {
      return this.get('row.itemIndex') * this.get('rowHeight');
    }).property('row.itemIndex', 'rowHeight'),

    // TODO(azirbel): Add explicit else case
    display: Ember['default'].computed(function () {
      if (!this.get('content')) {
        return 'none';
      }
    }).property('content'),

    // Use `lastItem` (set manually) instead of the array's built-in `lastObject`
    // to avoid creating a controller for last row on table initialization.  If
    // this TableRow is the last row, then the row controller should have been
    // created and set to `lastItem` in RowArrayController, otherwise `lastItem`
    // is null.
    // (Artych) What should be done here?
    isLastRow: Ember['default'].computed(function () {
      return this.get('row') === this.get('tableComponent.bodyContent.lastItem');
    }).property('tableComponent.bodyContent.lastItem', 'row'),

    // TODO(azirbel): Could simplify slightly via
    // this.set('row.isHovered', true) and remove the temp variable.
    // Also applies below/elsewhere.
    mouseEnter: function mouseEnter() {
      var row = this.get('row');
      if (row) {
        row.set('isHovered', true);
      }
    },

    mouseLeave: function mouseLeave() {
      var row = this.get('row');
      if (row) {
        row.set('isHovered', false);
      }
    },

    click: function click(event) {
      var row = this.get('row');
      if (row) {
        this.sendAction('rowDidClick', this.get('row'), event);
      }
    },

    actions: {
      toggleRowCollapse: function toggleRowCollapse(row) {
        this.sendAction('toggleRowCollapse', row);
      }
    }
  });

});