define('ember-table/components/lazy-table-block', ['exports', 'ember', 'ember-table/mixins/style-bindings'], function (exports, Ember, StyleBindingsMixin) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(StyleBindingsMixin['default'], {
    classNames: ['lazy-list-container', 'ember-table-table-block'],
    styleBindings: ['width', 'height'],
    columns: null,
    scrollLeft: null,
    content: null,
    itemViewClass: null,
    rowHeight: null,
    scrollTop: null,
    startIndex: null,
    blockWidth: null,

    height: Ember['default'].computed(function () {
      return this.get('content.length') * this.get('rowHeight');
    }).property('content.length', 'rowHeight'),

    width: Ember['default'].computed.alias('blockWidth'),

    numChildViews: Ember['default'].computed(function () {
      return this.get('numItemsShowing') + 2;
    }).property('numItemsShowing'),

    onScrollLeftDidChange: Ember['default'].observer('scrollLeft', function () {
      this.$().scrollLeft(this.get('scrollLeft'));
    }),

    lazyContent: Ember['default'].computed('numChildViews', 'startIndex', 'content.length', function () {
      var content = this.get('content') || Ember['default'].A([]);
      var startIndex = this.get('startIndex') || 0;
      var numChildViews = this.get('numChildViews') || 0;
      var endIndex = startIndex + numChildViews;
      return content.slice(startIndex, endIndex).map(function (row) {
        row.set('isHovered', false);
        return row;
      });
    }),

    actions: {
      rowDidClick: function rowDidClick(row, event) {
        this.sendAction('rowDidClick', row, event);
      },

      toggleRowCollapse: function toggleRowCollapse(row) {
        this.sendAction('toggleRowCollapse', row);
      }
    }

  });

});