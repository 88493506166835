define('ember-table/components/column-sortable-indicator', ['exports', 'ember', 'ember-table/mixins/style-bindings'], function (exports, Ember, StyleBindingsMixin) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(StyleBindingsMixin['default'], {
    classNames: 'ember-table-column-sortable-indicator',
    classNameBindings: 'isShowingSortableIndicator:active',
    styleBindings: ['left', 'height'],
    left: Ember['default'].computed.alias('sortableIndicatorLeft'),
    height: Ember['default'].computed.alias('tableHeight'),

    sortableIndicatorLeft: null,
    tableHeight: null,
    isShowingSortableIndicator: null
  });

});