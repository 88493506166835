define('ember-table/mixins/show-horizontal-scroll', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  // HACK: We want the horizontal scroll to show on mouse enter and leave.
  exports['default'] = Ember['default'].Mixin.create({
    mouseEnter: function mouseEnter(event) {
      var $tablesContainer = Ember['default'].$(event.target).parents('.ember-table-tables-container');
      var $horizontalScroll = $tablesContainer.find('.antiscroll-scrollbar-horizontal');
      $horizontalScroll.addClass('antiscroll-scrollbar-shown');
    },

    mouseLeave: function mouseLeave(event) {
      var $tablesContainer = Ember['default'].$(event.target).parents('.ember-table-tables-container');
      var $horizontalScroll = $tablesContainer.find('.antiscroll-scrollbar-horizontal');
      $horizontalScroll.removeClass('antiscroll-scrollbar-shown');
    }
  });

});