define('ember-table/components/body-table-container', ['exports', 'ember', 'ember-table/mixins/table-container', 'ember-table/mixins/show-horizontal-scroll', 'ember-table/mixins/mouse-wheel-handler', 'ember-table/mixins/touch-move-handler', 'ember-table/mixins/scroll-handler'], function (exports, Ember, TableContainer, ShowHorizontalScrollMixin, MouseWheelHandlerMixin, TouchMoveHandlerMixin, ScrollHandlerMixin) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(TableContainer['default'], MouseWheelHandlerMixin['default'], TouchMoveHandlerMixin['default'], ScrollHandlerMixin['default'], ShowHorizontalScrollMixin['default'], {

    classNames: ['ember-table-table-container', 'ember-table-body-container', 'antiscroll-wrap'],

    bodyHeight: null,
    bodyWidth: null,
    scrollLeft: null,
    height: Ember['default'].computed.alias('bodyHeight'),
    width: Ember['default'].computed.alias('bodyWidth'),
    // TODO (Artych) where it should be
    scrollElementSelector: '.antiscroll-inner',

    _numItemsShowing: Ember['default'].computed(function () {
      return Math.floor(this.get('bodyHeight') / this.get('rowHeight'));
    }).property('bodyHeight', 'rowHeight'),

    // _scrollTop: 0,
    _scrollTopIndex: 0,

    _startIndex: Ember['default'].computed('_scrollTopIndex', 'bodyContent.length', '_numItemsShowing', function () {
      var numContent = this.get('bodyContent.length');
      var numViews = this.get('_numItemsShowing');
      // var rowHeight = this.get('rowHeight');
      var index = this.get('_scrollTopIndex');
      // var index = Math.floor(_scrollTop / rowHeight);
      // Adjust start index so that end index doesn't exceed content length
      if (index + numViews >= numContent) {
        index = numContent - numViews;
      }
      return Math.max(index, 0);
    }),

    onScroll: function onScroll(event) {
      if (this.get('rowHeight') > 0) {
        this.set('_scrollTopIndex', Math.floor(event.target.scrollTop / this.get('rowHeight')));
      }
      return event.preventDefault();
    },

    // `event` here is a jQuery event
    onMouseWheel: function onMouseWheel(event, delta, deltaX, deltaY) {
      if (Math.abs(deltaX) <= Math.abs(deltaY)) {
        return;
      }
      var scrollLeft = this.$('.ember-table-right-table-block').scrollLeft() + deltaX;
      this.set('scrollLeft', scrollLeft);
      event.preventDefault();
    },

    // `event` here is a jQuery event
    onTouchMove: function onTouchMove(event, deltaX, deltaY) {
      if (Math.abs(deltaX) <= Math.abs(deltaY)) {
        return;
      }
      var scrollLeft = this.$('.ember-table-right-table-block').scrollLeft() + deltaX;
      this.set('scrollLeft', scrollLeft);
      event.preventDefault();
    },

    actions: {
      rowDidClick: function rowDidClick(row, event) {
        this.sendAction('rowDidClick', row, event);
      },

      toggleRowCollapse: function toggleRowCollapse(row) {
        this.sendAction('toggleRowCollapse', row);
      }
    }
  });

});