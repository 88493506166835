define('ember-table/mixins/style-bindings', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  // TODO(azirbel): This needs to be an external dependency.
  exports['default'] = Ember['default'].Mixin.create({
    concatenatedProperties: ['styleBindings'],
    attributeBindings: ['style'],
    unitType: 'px',
    createStyleString: function createStyleString(styleName, property) {
      var value;
      value = this.get(property);
      if (Ember['default'].isNone(value)) {
        return;
      }
      if (Ember['default'].typeOf(value) === 'number') {
        value = value + this.get('unitType');
      }
      return Ember['default'].String.dasherize("" + styleName) + ":" + value + ";";
    },
    applyStyleBindings: Ember['default'].on('init', Ember['default'].observer('styleBindings', function () {
      var lookup,
          properties,
          styleBindings,
          styleComputed,
          styles,
          _this = this;
      styleBindings = this.get('styleBindings');
      if (!styleBindings) {
        return;
      }
      lookup = {};
      styleBindings.forEach(function (binding) {
        var property, style, tmp;
        tmp = binding.split(':');
        property = tmp[0];
        style = tmp[1];
        lookup[style || property] = property;
      });
      styles = Object.keys(lookup);
      properties = styles.map(function (style) {
        return lookup[style];
      });
      styleComputed = Ember['default'].computed(function () {
        var styleString, styleTokens;
        styleTokens = styles.map(function (style) {
          return _this.createStyleString(style, lookup[style]);
        });
        styleString = styleTokens.join('');
        if (styleString.length !== 0) {
          return new Ember['default'].Handlebars.SafeString(styleString);
        }
      });
      styleComputed.property.apply(styleComputed, properties);
      return Ember['default'].defineProperty(this, 'style', styleComputed);
    }))
  });

});