define('ember-table/components/scroll-container', ['exports', 'ember', 'ember-table/mixins/style-bindings', 'ember-table/mixins/scroll-handler'], function (exports, Ember, StyleBindingsMixin, ScrollHandlerMixin) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(StyleBindingsMixin['default'], ScrollHandlerMixin['default'], {

    classNames: ['ember-table-scroll-container'],
    styleBindings: ['left', 'width', 'height'],
    scrollElementSelector: '.antiscroll-inner',

    scrollContainerWidth: null,
    fixedColumnsWidth: null,

    width: Ember['default'].computed.alias('scrollContainerWidth'),
    // 10 is the height of the horizontal scrollbar
    height: 10,
    left: Ember['default'].computed.alias('fixedColumnsWidth'),
    scrollLeft: 0,

    // `event` here is a jQuery event
    onScroll: function onScroll(event) {
      this.sendAction('scrollLeftDidChange', event.target.scrollLeft);
      event.preventDefault();
    },

    onScrollLeftDidChange: Ember['default'].observer('scrollLeft', 'scrollElementSelector', function () {
      var selector = this.get('scrollElementSelector');
      this.$(selector).scrollLeft(this.get('scrollLeft'));
    })
  });

});