define('ember-table/components/footer-table-container', ['exports', 'ember', 'ember-table/mixins/table-container', 'ember-table/mixins/show-horizontal-scroll', 'ember-table/mixins/mouse-wheel-handler', 'ember-table/mixins/touch-move-handler'], function (exports, Ember, TableContainer, ShowHorizontalScrollMixin, MouseWheelHandlerMixin, TouchMoveHandlerMixin) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(TableContainer['default'], MouseWheelHandlerMixin['default'], TouchMoveHandlerMixin['default'], ShowHorizontalScrollMixin['default'], {

    classNames: ['ember-table-table-container', 'ember-table-fixed-table-container', 'ember-table-footer-container'],
    styleBindings: 'top',
    height: Ember['default'].computed.alias('footerHeight'),
    width: Ember['default'].computed.alias('tableContainerWidth'),
    scrollLeft: null,
    footerHeight: null,
    tableContainerWidth: null,
    headerHeight: null,
    tableContentHeight: null,
    bodyHeight: null,

    top: Ember['default'].computed(function () {
      var headerHeight = this.get('headerHeight');
      var contentHeight = this.get('tableContentHeight') + headerHeight;
      var bodyHeight = this.get('bodyHeight') + headerHeight;
      if (contentHeight < bodyHeight) {
        return contentHeight;
      } else {
        return bodyHeight;
      }
    }).property('bodyHeight', 'headerHeight', 'tableContentHeight'),

    onMouseWheel: function onMouseWheel(event, delta, deltaX) {
      var scrollLeft = this.$('.ember-table-right-table-block').scrollLeft() + deltaX;
      this.set('scrollLeft', scrollLeft);
      event.preventDefault();
    },

    onTouchMove: function onTouchMove(event, deltaX) {
      var scrollLeft = this.$('.ember-table-right-table-block').scrollLeft() + deltaX;
      this.set('scrollLeft', scrollLeft);
      event.preventDefault();
    }
  });

});