define('ember-table/components/scroll-panel', ['exports', 'ember', 'ember-table/mixins/style-bindings'], function (exports, Ember, StyleBindingsMixin) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(StyleBindingsMixin['default'], {
    classNames: ['ember-table-scroll-panel'],
    styleBindings: ['width', 'height'],
    width: Ember['default'].computed.alias('tableColumnsWidth'),
    height: Ember['default'].computed.alias('tableContentHeight'),

    tableColumnsWidth: null,
    tableContentHeight: null

  });

});