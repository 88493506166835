define('ember-table/components/header-cell', ['exports', 'ember', 'ember-table/mixins/style-bindings', 'ember-table/mixins/register-table-component'], function (exports, Ember, StyleBindingsMixin, RegisterTableComponentMixin) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(StyleBindingsMixin['default'], RegisterTableComponentMixin['default'], {
    // ---------------------------------------------------------------------------
    // API - Inputs
    // ---------------------------------------------------------------------------

    // TODO: Doc
    classNames: ['ember-table-cell', 'ember-table-header-cell'],
    classNameBindings: ['column.isSortable:sortable', 'column.textAlign'],
    styleBindings: ['width', 'height'],

    // ---------------------------------------------------------------------------
    // Internal properties
    // ---------------------------------------------------------------------------

    column: Ember['default'].computed.alias('content'),
    width: Ember['default'].computed.alias('column.width'),
    minWidth: Ember['default'].computed.alias('column.minWidth'),
    maxWidth: Ember['default'].computed.alias('column.maxWidth'),
    nextResizableColumn: Ember['default'].computed.alias('column.nextResizableColumn'),

    headerHeight: null,
    height: Ember['default'].computed.alias('headerHeight'),
    columnMode: null,
    columnsFillTable: null,

    effectiveMinWidth: Ember['default'].computed(function () {
      if (this.get('columnMode') === 'standard') {
        return this.get('minWidth');
      }
      var nextColumnMaxDiff = this.get('nextResizableColumn.maxWidth') - this.get('nextResizableColumn.width');
      if (this.get('minWidth') && nextColumnMaxDiff) {
        return Math.min(this.get('minWidth'), this.get('width') - nextColumnMaxDiff);
      } else if (this.get('minWidth')) {
        return this.get('minWidth');
      } else {
        return this.get('width') - nextColumnMaxDiff;
      }
    }).property('width', 'minWidth', 'columnMode', 'nextResizableColumn.{width,maxWidth}'),

    effectiveMaxWidth: Ember['default'].computed(function () {
      if (this.get('columnMode') === 'standard') {
        return this.get('maxWidth');
      }
      var nextColumnMaxDiff = this.get('nextResizableColumn.width') - this.get('nextResizableColumn.minWidth');
      if (this.get('maxWidth') && !Ember['default'].isNone(nextColumnMaxDiff)) {
        return Math.min(this.get('maxWidth'), this.get('width') + nextColumnMaxDiff);
      } else if (this.get('maxWidth')) {
        return this.get('maxWidth');
      } else {
        return this.get('width') + nextColumnMaxDiff;
      }
    }).property('width', 'minWidth', 'columnMode', 'nextResizableColumn.{width,minWidth}'),

    // jQuery UI resizable option
    resizableOption: Ember['default'].computed(function () {
      return {
        handles: 'e', // Show the "east"/"right" handle
        // We need about 10px as absolute minimums for the columns
        minWidth: Math.max(this.get('effectiveMinWidth') || 0, 10),
        maxWidth: this.get('effectiveMaxWidth'),
        // TODO(azirbel): This is unexpected and needs documentation or removal
        grid: this.get('column.snapGrid'),
        resize: Ember['default'].$.proxy(this.onColumnResize, this),
        stop: Ember['default'].$.proxy(this.onColumnResize, this)
      };
    }).property('effectiveMinWidth', 'effectiveMaxWidth'),

    didRender: function didRender() {
      Ember['default'].run.scheduleOnce('afterRender', this, 'didRenderCalculations');
    },
    didRenderCalculations: function didRenderCalculations() {
      this.elementSizeDidChange();
      this.recomputeResizableHandle();
    },

    willDestroyElement: function willDestroyElement() {
      if (this.$().is('.ui-resizable')) {
        this.$().resizable('destroy');
      }

      Ember['default'].run.cancel(this._scheduledElementSizeDidChange);

      this._super();
    },

    _isResizable: Ember['default'].computed(function () {
      if (this.get('columnMode') === 'standard') {
        return this.get('column.isResizable');
      } else {
        return this.get('column.isResizable') && this.get('nextResizableColumn');
      }
    }).property('column.isResizable', 'columnMode', 'nextResizableColumn'),

    // `event` here is a jQuery event
    onColumnResize: function onColumnResize(event, ui) {
      var newWidth = Math.round(ui.size.width);
      if (this.get('columnMode') === 'standard') {
        this.get('column').resize(newWidth);
        this.set('columnsFillTable', false);
      } else {
        var diff = this.get('width') - newWidth;
        this.get('column').resize(newWidth);
        this.get('nextResizableColumn').resize(this.get('nextResizableColumn.width') + diff);
      }

      this.elementSizeDidChange();

      // Trigger the table resize (and redraw of layout) when resizing is done
      if (event.type === 'resizestop') {
        this.get('tableComponent').elementSizeDidChange();
      }

      this.get('context').sendAction('onColumnResized', this.get('column'), newWidth);
    },

    /**
     * A Ember timer object representing a scheduled call to
     * `elementSizeDidChange`.
     * @private
     * @type {Object}
     */
    _scheduledElementSizeDidChange: null,

    elementSizeDidChange: function elementSizeDidChange() {
      var maxHeight = 0;
      // TODO(Louis): This seems bad...
      Ember['default'].$('.ember-table-header-block .ember-table-content').each(function () {
        var thisHeight = Ember['default'].$(this).outerHeight();
        if (thisHeight > maxHeight) {
          maxHeight = thisHeight;
        }
      });

      this.set('tableComponent._contentHeaderHeight', maxHeight);
    },

    cellWidthDidChange: Ember['default'].observer(function () {
      this._scheduledElementSizeDidChange = Ember['default'].run.scheduleOnce('afterRender', this, this.elementSizeDidChange);
    }, 'width'),

    resizableObserver: Ember['default'].observer('resizableOption', 'column.isResizable', 'columnMode', 'nextResizableColumn', function () {
      this.recomputeResizableHandle();
    }),

    recomputeResizableHandle: function recomputeResizableHandle() {
      if (this.get('_isResizable')) {
        this.$().resizable(this.get('resizableOption'));
      } else {
        if (this.$().is('.ui-resizable')) {
          this.$().resizable('destroy');
        }
      }
    },

    actions: {
      toggleTableCollapse: function toggleTableCollapse() {
        this.sendAction('toggleTableCollapse');
      }
    }
  });

});